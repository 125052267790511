<template>
  <span
    class="v-metadata"
    itemprop="productID"
    >{{ productBase.ID }}</span
  >
  <span
    v-if="!stringIsNullOrWhitespace(translate('general.restName'))"
    class="v-metadata"
    itemprop="brand"
    v-html="translate('general.restName')"
  />
  <div
    class="v-metadata"
    itemscope
    itemprop="offers"
    itemtype="https://schema.org/AggregateOffer"
  >
    <link
      v-if="!menuStore.ProductsInStopList.includes(productBase.ID)"
      href="http://schema.org/InStock"
      itemprop="availability"
    />
    <span
      :content="restaurantStore.CurrencySettings?.CurrencyISO ?? 'USD'"
      itemprop="priceCurrency"
    />
    <span
      :content="productBase.PriceModified"
      itemprop="price"
    />
  </div>
</template>

<script setup lang="ts">
import type { ProductInList } from '~types/menuStore'

import { useCommon } from '@arora/common'

defineProps<{
  productBase: ProductInList
}>()

const menuStore = useMenuStore()
const restaurantStore = useRestaurantStore()

const { stringIsNullOrWhitespace } = useCommon()
const { translate } = useI18nSanitized()
</script>

<style lang="scss">
.v-item-page {
  margin-bottom: 1.1rem;
}

.v-item-page-extra {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
